<template>
  <AppExpansionItem
    ref="expansion"
    class="form-field"
    :label="$t('title.webinar')"
    @toggle="onToggleSection($event, ENUMS.TOGGLE_FILED_NAMES.WEBINAR)"
  >
    <div class="form-builder__option form-builder__option_horizontal">
      <AppInput
        v-if="form.settings.fieldsLabelsEnabled"
        v-model="formPart.webinarFieldLabel"
        :label="$t('label.label')"
      />
      <AppInput
        v-model="formPart.webinarFieldPlaceholder"
        :label="$t('label.placeholder')"
      />
      <AppInput
        v-model="formPart.webinarFieldErrorMessage"
        :label="$t('label.errorMessage')"
      />
    </div>
    <div class="form-builder__option form-builder__option_horizontal">
      <AppToggle
        v-model="formPart.webinarFieldIconEnabled"
        :label="$t('label.showIconForField')"
        left-label
      />
      <div
        v-if="formPart.webinarFieldIconEnabled"
        class="icon-selector"
      >
        <div>{{ $t('label.icon') }}</div>
        <AppIconPicker v-model="formPart.webinarFieldIcon"/>
        <AppButtonToggle
          v-model="formPart.webinarFieldIconPosition"
          :options="iconPositionOptions"
          toggle-color="btn-toggle-selected"
          toggle-text-color="black"
          text-color="black"
          padding="12px 25px"
        />
      </div>
    </div>
    <div class="form-builder__option form-builder__option_horizontal">
      <AppSelect
        v-model="formPart.webinarProviderId"
        class="select-add-button"
        popup-content-class="select-add-button-content"
        option-value="id"
        option-label="title"
        :options="providers"
        :loading="loading.provider"
        :label="$t('label.webinarProvider')"
        :placeholder="$t('placeholder.selectWebinarProvider')"
        :rules="[rules.REQUIRED]"
        emit-value
        map-options
        @input="formPart.webinarId = null"
      >
        <template #before-options>
          <div class="btn-block">
            <AppButton
              :label="$t('button.addNewWebinarProvider')"
              class="text-14-400"
              text-color="black"
              padding="3px 16px"
              @click="showCreateDialog = true"
            />
          </div>
        </template>
        <template v-slot:no-option>
          <div class="btn-block">
            <AppButton
              :label="$t('button.addNewWebinarProvider')"
              class="text-14-400"
              text-color="black"
              padding="3px 16px"
              @click="showCreateDialog = true"
            />
          </div>
        </template>
        <template v-slot:option="scope">
          <div
            class="option"
            v-on="scope.itemEvents"
          >
            <div class="option-inner">
              <div class="option-label">
                {{ scope.opt.title }}
              </div>
            </div>
          </div>
        </template>
      </AppSelect>
      <AppSelect
        v-if="formPart.webinarProviderId && !isWebinaris"
        v-model="formPart.webinarId"
        :label="$t('label.webinar')"
        :loading="loading.webinar"
        :options="webinars"
        :rules="[rules.REQUIRED]"
        emit-value
        map-options
      />
      <AppSelect
        v-model="formPart.webinarFieldDateFormat"
        :options="$options.dateOptions"
        :label="$t('label.dateField')"
        emit-value
        map-options
      />
      <AppSelect
        v-model="formPart.webinarFieldTimeFormat"
        :options="$options.timeOptions"
        :label="$t('label.dateField')"
        emit-value
        map-options
      />
      <AppSelect
        v-model="formPart.webinarSessionsLimit"
        :options="webinarSessionOptions"
        :label="$t('label.webinarSessionLimit')"
        emit-value
        map-options
      />
    </div>
    <div class="form-builder__option form-builder__option_horizontal">
      <AppToggle
        v-model="formPart.isMaxSeatsEnabled"
        :label="$t('label.isMaxSeatsEnabled')"
        left-label
      />
      <AppInput
        v-if="formPart.isMaxSeatsEnabled"
        v-model="formPart.maxSeatsNumber"
        :rules="[rules.REQUIRED]"
        :label="$t('label.maxSeatsNumber')"
        @input="formPart.maxSeatsNumber = castValueAsNumber($event)"
      />
    </div>
    <NewProviderDialog
      :is-open="showCreateDialog"
      :options="$options.webinarOptions"
      :title="$t('title.addWebinarProvider')"
      :select-label="$t('label.webinarProvider')"
      :submit-label="$t('button.save')"
      :create-fn="createWebinar"
      @close="showCreateDialog = false"
      @input="formPart.webinarProviderId = $event"
    />
  </AppExpansionItem>
</template>

<script>
import AppExpansionItem from 'common/components/AppExpansionItem';
import AppIconPicker from 'common/components/AppIconPicker';
import AppInput from 'common/components/AppInput';
import AppSelect from 'common/components/AppSelect';
import AppToggle from 'common/components/AppToggle';
import AppButton from 'common/components/buttons/AppButton';
import AppButtonToggle from 'common/components/buttons/AppButtonToggle';
import castValueAsNumber from 'common/helpers/castNumberInput';
import handleError from 'common/helpers/handleError';
import rules from 'common/helpers/rules';
import NewProviderDialog from 'common/pages/dashboard/components/NewProviderDialog';
import { builderMixin, formFieldsMixin } from 'pages/dashboard/form/mixins';
import { debounce } from 'quasar';
import webinars from 'src/helpers/data/webinars';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'WebinarSettings',
  shortName: ENUMS.TOGGLE_FILED_NAMES.WEBINAR,
  dateOptions: [
    {
      label: 'MM-DD-YYYY / 07-22-2022',
      value: 'MM-DD-YYYY',
    },
    {
      label: 'DD-MM-YYYY / 22-07-2022',
      value: 'DD-MM-YYYY',
    },
    {
      label: 'DD.MM.YYYY / 22.07.2022',
      value: 'DD.MM.YYYY',
    },
    {
      label: 'DD/MM/YYYY / 22/07/2022',
      value: 'DD/MM/YYYY',
    },
    {
      label: 'YYYY-MM-DD / 2022-07-22',
      value: 'YYYY-MM-DD',
    },
    {
      label: 'day of the week, dd MM / Friday, 22 Jul',
      value: 'dddd, DD MMM',
    },
    {
      label: 'day of the week, dd. MM / Friday, 22. Jul',
      value: 'dddd, DD. MMM',
    },
  ],
  timeOptions: [
    {
      label: '8:22 AM',
      value: 'h:mm A',
    },
    {
      label: '20:22',
      value: 'HH:mm',
    },
  ],
  webinarOptions: webinars.map((item) => {
    const value = { ...item };
    if (value.value === ENUMS.WEBINAR_TYPES.ZOOM) {
      value.fields = value.fields.filter((field) => field.name !== 'accountName');
    }
    return value;
  }),
  components: {
    NewProviderDialog,
    AppButton,
    AppSelect,
    AppButtonToggle,
    AppIconPicker,
    AppToggle,
    AppInput,
    AppExpansionItem,
  },
  mixins: [builderMixin, formFieldsMixin],
  data() {
    return {
      showCreateDialog: false,
      webinars: [],
      loading: {
        provider: false,
        webinar: false,
      },
    };
  },
  computed: {
    ...mapState('dashboard', {
      providers: 'webinars',
    }),
    webinarSessionOptions() {
      const items = Array.from({ length: 10 }, (_, index) => ({
        label: index + 1,
        value: index + 1,
      }));
      items.unshift({
        label: this.$t('label.all'),
        value: 1000,
      });
      return items;
    },
    currentProvider() {
      return this.providers.find((provider) => provider.id === this.formPart?.webinarProviderId);
    },
    isWebinaris() {
      return this.currentProvider?.type === ENUMS.WEBINAR_TYPES.WEBINARIS;
    },
    rules: () => rules,
  },
  watch: {
    'formPart.webinarProviderId': {
      immediate: true,
      handler: debounce(function () {
        this.fetchWebinars();
      }, 500),
    },
    isWebinaris() {
      if (this.isWebinaris) {
        // eslint-disable-next-line
        this.formPart.webinarId = null;
      }
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    ...mapActions('dashboard', {
      createWebinar: 'createWebinar',
      fetchProviders: 'fetchWebinars',
    }),
    castValueAsNumber,
    async fetch() {
      try {
        this.loading.provider = true;
        await this.fetchProviders();
      } catch (error) {
        handleError(error);
      } finally {
        this.loading.provider = false;
      }
    },
    async fetchWebinars() {
      if (!this.formPart?.webinarProviderId) {
        return;
      }
      try {
        this.loading.webinar = true;
        const { data } = await this.$axios.get(`webinars/${this.formPart.webinarProviderId}/external`);
        this.webinars = Object.keys(data.data).map((index) => ({
          label: data.data[index],
          value: index,
        }));
      } catch (error) {
        this.webinars = [];
        handleError(error);
      } finally {
        this.loading.webinar = false;
      }
    },
  },
};
</script>
