<template>
  <AppExpansionItem
    ref="expansion"
    class="form-field"
    :label="$t('title.submitButton')"
    @toggle="onToggleSection($event, ENUMS.TOGGLE_FILED_NAMES.SUBMIT_BUTTON)"
  >
    <div
      v-if="form.settings.hasMultistep"
      class="form-builder__option form-builder__option_horizontal"
    >
      <AppInput
        v-model="formPart.nextButtonLabel"
        :label="$t('label.labelStep1')"
      />
      <div class="icon-picker-position">
        <div>{{ $t('label.showLeftIcon') }}</div>
        <AppIconPicker
          v-model="formPart.nextButtonLeftIcon"
          :disabled="!formPart.nextButtonLeftIconEnabled"
        />
        <AppToggle
          v-model="formPart.nextButtonLeftIconEnabled"
          left-label
        />
      </div>
      <div class="icon-picker-position">
        <div>{{ $t('label.showRightIcon') }}</div>
        <AppIconPicker
          v-model="formPart.nextButtonRightIcon"
          :disabled="!formPart.nextButtonRightIconEnabled"
        />
        <AppToggle
          v-model="formPart.nextButtonRightIconEnabled"
          left-label
        />
      </div>
    </div>
    <div class="form-builder__option form-builder__option_horizontal">
      <AppInput
        v-model="formPart.submitButtonLabel"
        :label="$t('label.labelSubmit')"
      />
      <div class="icon-picker-position">
        <div>{{ $t('label.showLeftIcon') }}</div>
        <AppIconPicker
          v-model="formPart.submitButtonLeftIcon"
          :disabled="!formPart.submitButtonLeftIconEnabled"
        />
        <AppToggle
          v-model="formPart.submitButtonLeftIconEnabled"
          left-label
        />
      </div>
      <div class="icon-picker-position">
        <div>{{ $t('label.showRightIcon') }}</div>
        <AppIconPicker
          v-model="formPart.submitButtonRightIcon"
          :disabled="!formPart.submitButtonRightIconEnabled"
        />
        <AppToggle
          v-model="formPart.submitButtonRightIconEnabled"
          left-label
        />
      </div>
    </div>
    <div
      v-if="form.settings.hasMultistep"
      class="form-builder__option flex justify-between items-center"
    >
      <div>{{ $t('label.backButtonIcon') }}</div>
      <AppIconPicker v-model="formPart.backButtonIcon"/>
    </div>
  </AppExpansionItem>
</template>

<script>
import AppExpansionItem from 'common/components/AppExpansionItem';
import AppIconPicker from 'common/components/AppIconPicker';
import AppInput from 'common/components/AppInput';
import AppToggle from 'common/components/AppToggle';
import { builderMixin, formFieldsMixin } from 'pages/dashboard/form/mixins';

export default {
  name: 'SubmitButtonSettings',
  shortName: ENUMS.TOGGLE_FILED_NAMES.SUBMIT_BUTTON,
  components: {
    AppIconPicker,
    AppToggle,
    AppInput,
    AppExpansionItem,
  },
  mixins: [builderMixin, formFieldsMixin],
};
</script>
