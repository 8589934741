<template>
  <AppExpansionItem
    ref="expansion"
    class="form-field draggable-field"
    :label="$t('title.name')"
    @toggle="onToggleSection($event, ENUMS.TOGGLE_FILED_NAMES.NAME)"
  >
    <template #header>
      <q-icon
        class="drag-icon"
        name="fa fa-grip-vertical"
      />
    </template>
    <div class="form-builder__option form-builder__option_horizontal">
      <AppInput
        v-if="form.settings.fieldsLabelsEnabled"
        v-model="formPart.nameFieldLabel"
        :placeholder="$t('placeholder.firstName')"
        :label="$t('label.label')"
      />
      <AppInput
        v-model="formPart.nameFieldPlaceholder"
        :placeholder="$t('placeholder.yourFirstName')"
        :label="$t('label.placeholder')"
      />
      <AppInput
        v-model="formPart.nameFieldErrorMessage"
        :placeholder="$t('placeholder.invalidFirstName')"
        :label="$t('label.errorMessage')"
      />
      <AppToggle
        v-model="formPart.lastNameFieldEnabled"
        :label="$t('label.showLastName')"
        :disable="isZoom"
        left-label
      >
        <q-tooltip
          v-if="isZoom"
          transition-hide="fade"
          transition-show="fade"
          content-class="bg-black text-15-400 q-px-md q-py-sm text-center"
          max-width="200px"
          anchor="bottom right"
          :offset="[-50, 15]"
        >
          {{ $t('text.lastNameRequiredForZoom') }}
        </q-tooltip>
      </AppToggle>
      <template v-if="formPart.lastNameFieldEnabled">
        <AppInput
          v-if="form.settings.fieldsLabelsEnabled"
          v-model="formPart.lastNameFieldLabel"
          :placeholder="$t('placeholder.lastName')"
          :label="$t('label.label')"
        />
        <AppInput
          v-model="formPart.lastNameFieldPlaceholder"
          :placeholder="$t('placeholder.yourLastName')"
          :label="$t('label.placeholder')"
        />
        <AppInput
          v-model="formPart.lastNameFieldErrorMessage"
          :placeholder="$t('placeholder.invalidLastName')"
          :label="$t('label.errorMessage')"
        />
      </template>
    </div>
    <div class="form-builder__option form-builder__option_horizontal">
      <AppToggle
        v-model="formPart.lastNameFieldIconEnabled"
        :label="$t('label.showIconForField')"
        left-label
      />
      <div
        v-if="formPart.lastNameFieldIconEnabled"
        class="icon-selector"
      >
        <div>{{ $t('label.icon') }}</div>
        <AppIconPicker v-model="formPart.lastNameFieldIcon"/>
        <AppButtonToggle
          v-model="formPart.lastNameFieldIconPosition"
          :options="iconPositionOptions"
          toggle-color="btn-toggle-selected"
          toggle-text-color="black"
          text-color="black"
          padding="12px 25px"
        />
      </div>
    </div>
  </AppExpansionItem>
</template>

<script>
import AppExpansionItem from 'common/components/AppExpansionItem';
import AppIconPicker from 'common/components/AppIconPicker';
import AppInput from 'common/components/AppInput';
import AppToggle from 'common/components/AppToggle';
import AppButtonToggle from 'common/components/buttons/AppButtonToggle';
import { builderMixin, formFieldsMixin } from 'pages/dashboard/form/mixins';
import { mapState } from 'vuex';

export default {
  name: 'NameSettings',
  shortName: ENUMS.TOGGLE_FILED_NAMES.NAME,
  components: {
    AppButtonToggle,
    AppIconPicker,
    AppToggle,
    AppInput,
    AppExpansionItem,
  },
  mixins: [builderMixin, formFieldsMixin],
  computed: {
    ...mapState('dashboard', ['webinars']),
    isZoom() {
      return this.webinars.find((item) => item.id === this.formPart?.webinarProviderId)?.type === ENUMS.WEBINAR_TYPES.ZOOM;
    },
  },
  watch: {
    isZoom() {
      if (this.isZoom) {
        this.formPart.lastNameFieldEnabled = true;
      }
    },
  },
};
</script>
