<template>
  <AppExpansionItem
    ref="expansion"
    class="form-field draggable-field"
    :label="$t('title.phone')"
    @toggle="onToggleSection($event, ENUMS.TOGGLE_FILED_NAMES.PHONE)"
  >
    <template #header>
      <AppToggle v-model="formPart.phoneFieldEnabled"/>
      <q-icon
        class="drag-icon"
        name="fa fa-grip-vertical"
      />
    </template>
    <div class="form-builder__option form-builder__option_horizontal">
      <AppInput
        v-if="form.settings.fieldsLabelsEnabled"
        v-model="formPart.phoneFieldLabel"
        :placeholder="$t('placeholder.phoneNumber')"
        :label="$t('label.label')"
      />
      <AppInput
        v-model="formPart.phoneFieldPlaceholder"
        :placeholder="$t('placeholder.yourPhoneNumber')"
        :label="$t('label.placeholder')"
      />
      <AppInput
        v-model="formPart.phoneFieldErrorMessage"
        :placeholder="$t('placeholder.invalidPhoneNumber')"
        :label="$t('label.errorMessage')"
      />
    </div>
    <div class="form-builder__option form-builder__option_horizontal">
      <AppToggle
        v-model="formPart.phoneFieldIconEnabled"
        :label="$t('label.showIconForField')"
        left-label
      />
      <div
        v-if="formPart.phoneFieldIconEnabled"
        class="icon-selector"
      >
        <div>{{ $t('label.icon') }}</div>
        <AppIconPicker v-model="formPart.phoneFieldIcon"/>
        <AppButtonToggle
          v-model="formPart.phoneFieldIconPosition"
          :options="iconPositionOptions"
          toggle-color="btn-toggle-selected"
          toggle-text-color="black"
          text-color="black"
          padding="12px 25px"
        />
      </div>
    </div>
    <div class="form-builder__option form-builder__option_horizontal">
      <AppToggle
        v-model="formPart.phoneFieldCountryDetectionEnabled"
        :label="$t('label.autoDetection')"
        left-label
      />
      <AppSelect
        v-model="formPart.phoneFieldCountries"
        :options="$options.countries"
        :label="$t('label.preSelectedCountries')"
        :class="['chip-select', {
          'chip-select_empty': !formPart.phoneFieldCountries.length,
        }]"
        :option-value="item => item.value.toLowerCase()"
        stack-label
        multiple
        emit-value
        map-options
      >
        <span
          v-if="!formPart.phoneFieldCountries.length"
          class="text-grey chip-select__placeholder"
          v-html="$t('placeholder.allCountries')"
        />
        <template v-slot:selected-item="scope">
          <q-chip
            removable
            icon-remove="fa-solid fa-xmark"
            :tabindex="scope.tabindex"
            class="chip-option"
            color="grey-3"
            text-color="black"
            @remove="scope.removeAtIndex(scope.index)"
          >
            {{ scope.opt.label }}, {{ scope.opt.phoneCode }}
          </q-chip>
        </template>
      </AppSelect>
    </div>
    <div class="form-builder__option form-builder__option_horizontal">
      <AppToggle
        v-model="formPart.phoneFieldRequired"
        :label="$t('label.isFieldRequired')"
        left-label
      />
    </div>
  </AppExpansionItem>
</template>

<script>
import AppExpansionItem from 'common/components/AppExpansionItem';
import AppIconPicker from 'common/components/AppIconPicker';
import AppInput from 'common/components/AppInput';
import AppSelect from 'common/components/AppSelect';
import AppToggle from 'common/components/AppToggle';
import AppButtonToggle from 'common/components/buttons/AppButtonToggle';
import countries from 'common/helpers/data/countryCodes.json';
import { builderMixin, formFieldsMixin } from 'pages/dashboard/form/mixins';

export default {
  name: 'PhoneSettings',
  shortName: ENUMS.TOGGLE_FILED_NAMES.PHONE,
  countries,
  components: {
    AppSelect,
    AppButtonToggle,
    AppIconPicker,
    AppToggle,
    AppInput,
    AppExpansionItem,
  },
  mixins: [builderMixin, formFieldsMixin],
  watch: {
    'formPart.phoneFieldEnabled': function (value, oldValue) {
      if (oldValue === undefined) {
        return;
      }
      const { expansion } = this.$refs;
      if ((value && !expansion.isOpen) || (!value && expansion.isOpen)) {
        expansion.isOpen = value;
      }
    },
  },
};
</script>
