<template>
  <AppExpansionItem
    ref="expansion"
    class="form-field draggable-field"
    :label="$t('title.email')"
    @toggle="onToggleSection($event, ENUMS.TOGGLE_FILED_NAMES.EMAIL)"
  >
    <template #header>
      <q-icon
        class="drag-icon"
        name="fa fa-grip-vertical"
      />
    </template>
    <div class="form-builder__option form-builder__option_horizontal">
      <AppInput
        v-if="form.settings.fieldsLabelsEnabled"
        v-model="formPart.emailFieldLabel"
        :placeholder="$t('placeholder.emailAddress')"
        :label="$t('label.label')"
      />
      <AppInput
        v-model="formPart.emailFieldPlaceholder"
        :placeholder="$t('placeholder.yourEmailAddress')"
        :label="$t('label.placeholder')"
      />
      <AppInput
        v-model="formPart.emailFieldErrorMessage"
        :placeholder="$t('placeholder.invalidEmailAddress')"
        :label="$t('label.errorMessage')"
      />
    </div>
    <div class="form-builder__option form-builder__option_horizontal">
      <AppToggle
        v-model="formPart.emailFieldIconEnabled"
        :label="$t('label.showIconForField')"
        left-label
      />
      <div
        v-if="formPart.emailFieldIconEnabled"
        class="icon-selector"
      >
        <div>{{ $t('label.icon') }}</div>
        <AppIconPicker v-model="formPart.emailFieldIcon"/>
        <AppButtonToggle
          v-model="formPart.emailFieldIconPosition"
          :options="iconPositionOptions"
          toggle-color="btn-toggle-selected"
          toggle-text-color="black"
          text-color="black"
          padding="12px 25px"
        />
      </div>
    </div>
  </AppExpansionItem>
</template>

<script>
import AppExpansionItem from 'common/components/AppExpansionItem';
import AppIconPicker from 'common/components/AppIconPicker';
import AppInput from 'common/components/AppInput';
import AppToggle from 'common/components/AppToggle';
import AppButtonToggle from 'common/components/buttons/AppButtonToggle';
import { builderMixin, formFieldsMixin } from 'pages/dashboard/form/mixins';

export default {
  name: 'EmailSettings',
  shortName: ENUMS.TOGGLE_FILED_NAMES.EMAIL,
  components: {
    AppButtonToggle,
    AppIconPicker,
    AppToggle,
    AppInput,
    AppExpansionItem,
  },
  mixins: [builderMixin, formFieldsMixin],
};
</script>
