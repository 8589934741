<template>
  <div>
    <AppCard
      ref="card"
      class="form-fields-page__panel"
      body-class="flex column form-fields-body"
      header-color="bg-grey-11"
      :title="$t('label.formFields')"
      bordered
    >
      <WebinarSettings/>
      <component
        :is="component.name"
        v-for="component in orderedComponents"
        :key="`${component.position}-${component.name}`"
        :data-id="component.name"
      />
      <SubmitButtonSettings/>
    </AppCard>
    <div class="page-hint">
      {{ $t('text.formFieldPageHint') }}
    </div>
  </div>
</template>

<script>
import AppCard from 'common/components/AppCard';
import AppPanel from 'common/components/AppPanel';
import { builderMixin } from 'pages/dashboard/form/mixins';
import Sortable from 'sortablejs';
import EmailSettings from './components/form-fields/EmailSettings';
import NameSettings from './components/form-fields/NameSettings';
import PhoneSettings from './components/form-fields/PhoneSettings';
import SubmitButtonSettings from './components/form-fields/SubmitButtonSettings';
import WebinarSettings from './components/form-fields/WebinarSettings';

export default {
  name: 'FormFieldsPage',
  stepName: ENUMS.STEPS.FORM_FIELDS,
  components: {
    AppCard,
    EmailSettings,
    NameSettings,
    PhoneSettings,
    SubmitButtonSettings,
    WebinarSettings,
    AppPanel,
  },
  mixins: [builderMixin],
  data() {
    return {
      sortable: undefined,
    };
  },
  computed: {
    orderedComponents() {
      return [
        {
          position: this.formPart.emailFieldPosition,
          field: 'emailFieldPosition',
          name: 'EmailSettings',
        },
        {
          position: this.formPart.nameFieldPosition,
          field: 'nameFieldPosition',
          name: 'NameSettings',
        },
        {
          position: this.formPart.phoneFieldPosition,
          field: 'phoneFieldPosition',
          name: 'PhoneSettings',
        },
      ].sort((a, b) => {
        if (a.position > b.position) {
          return 1;
        }
        if (a.position < b.position) {
          return -1;
        }
        return 0;
      });
    },
  },
  created() {
    this.$root.$on(ENUMS.EVENTS.ON_TOGGLE_FORM_SETTING, this.closeInactiveForm);
  },
  mounted() {
    this.sortable = new Sortable(document.querySelector('.form-fields-body'), {
      handle: '.drag-icon',
      draggable: '.draggable-field',
      onEnd: this.onEnd,
    });
  },
  beforeDestroy() {
    this.$root.$off(ENUMS.EVENTS.ON_TOGGLE_FORM_SETTING, this.closeInactiveForm);
  },
  methods: {
    onEnd() {
      const list = this.sortable.toArray();
      const changes = {};
      list.forEach((item, index) => {
        const component = this.orderedComponents.find(({ name }) => name === item);
        changes[component.field] = index * 10;
      });
      this.formPart = {
        ...this.formPart,
        ...changes,
      };
    },
    closeInactiveForm({ status, name }) {
      if (!status) {
        return;
      }

      let openedComponent;
      this.$refs.card?.$slots.default
        .forEach(({ componentInstance }) => {
          if (componentInstance.$options.shortName !== name) {
            componentInstance.$refs.expansion.isOpen = false;
          } else {
            openedComponent = componentInstance;
          }
        });

      this.$nextTick(() => {
        const headerOffset = 110;
        const elementPosition = openedComponent.$el.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.form-fields-page__panel {
  ::v-deep .form-field {
    &:not(:last-child) {
      border-bottom: 1px solid #E3E3E3;
    }

    .drag-icon {
      color: #D5D5D5;
      cursor: pointer;
    }

    .body {
      .q-field__control {
        background: #fff;
      }

      .form-builder__option_horizontal {
        .app-input,
        .app-select {
          grid-template-columns: 1fr 2.6fr;
        }
      }
    }
  }

  .sortable-ghost {
    box-shadow: 0 0 5px 0 $grey-2;
    transition: all 0.5s ease;
  }
}
</style>
